* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: brown black;
  user-select: none;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: brown;
}

html, body {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;

}
