/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.wrap1 {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.shroud {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .3);
}

.background {
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
}

.backgrounddevice {
  display: none;
}

@media screen and (max-width: 500px) {

  .background {
    display: none;
  }

  .backgrounddevice {
    display: block;
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
  }

}
