/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.wrapabout {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.aboutcont {
  width: 75vw;
  height: 60vh;
  border: 1px solid brown;
  border-radius: 10px;
  padding: 30px;
  background: rgba(0, 0, 0, .8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  -webkit-box-pack: space-between;
      -ms-flex-pack: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.profilo {
  position: relative;
  width: auto;
  height: 100%;
  border-radius: 10px;
}

.txt {
  font-size: 1.5vw;
  color: white;
  font-family: Courier;
  text-align: center;
}

.closeabout {
  position: absolute;
  right: 1%;
  top: 2%;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.closeabout:before, .closeabout:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 3px;
  background-color: brown;
}

.closeabout:before {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.closeabout:after {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media screen and (max-width: 1024px) {

  .wrapabout {
    position: absolute;
    width: 100vw;
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .aboutcont {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
    width: 70vw;
    top: 100px;
  }

  .profilo {
    width: 100%;
    height: auto;
  }

  .txt {
    top: 0;
    left: 0;
    font-size: 20px;
    width: 100%;
    text-align: center;
  }

  .closeabout {
    right: 8%;
    top: 5%;
  }

}


@media screen and (max-width: 500px) {

  .aboutcont {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
    width: 85vw;
  }

  .profilo {
    width: 80%;
    height: auto;
  }

  .txt {
    top: 0;
    left: 0;
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
}
