.lang {
  position: absolute;
  left: 2%;
  top: 3%;
  display: flex;
  align-items: center;
  z-index: 100;
  gap: 20px;
}

.flag {
  width: 25px;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width: 500px) {

  .flag {
    width: 25px;
  }

  .lang {
    left: 5%;
  }

}
