/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

h1 {
  color: white;
}

.closeproject {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.closeproject:before, .closeproject:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 3px;
  background-color: brown;
}

.closeproject:before {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.closeproject:after {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.projectWrap {
  position: absolute;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.header {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.titolo {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-size: 5vh;
}

p {
  color: white;
}

.page1 {
  border-bottom: 1px solid black;
}

.cover {
  margin-bottom: 20px;
}

.more {
  margin-top: 30px;
  text-decoration: none;
  color: white;
  font-size: 3vh;
  font-family: Arial;
  -webkit-text-decoration: underline brown;
          text-decoration: underline brown;
}

@media screen and (max-width: 1024px) {
  
  .page1,
  .page2
   {
    width: 90%;
  }

  .cover {
    width: 90%;
  }
}
