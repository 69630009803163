/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.wrap {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.main {
  position: absolute;
  height: auto;
  width: auto;
  top: 40%;
}


.name {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 40vw;
  min-width: 768px;
  height: auto;
  cursor: default;
  -webkit-transition: color .3s;
  -o-transition: color .3s;
  transition: color .3s;
  border-bottom: 1px solid brown;
}

.first {
  letter-spacing: 15px;
  font-size: 10vh;
  height: 12vh;
  font-family: 'Six Caps', sans-serif;
  color: white;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
  text-shadow: 3px 3px black;
}

.second {
  letter-spacing: 15px;
  font-size: 15vh;
  height: 17.5vh;
  font-family: 'Six Caps', sans-serif;
  color: brown;
  text-shadow: 3px 3px black;
}

.copy {
  position: absolute;
  color: white;
  left: 0;
  bottom: 0;
}

.divide0 {
  height: 1px;
  background: brown;
  position: relative;
  top: 120px;
}

.bar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 10px;
}

.About,
.Works,
.Projects {
  font-size: 5.7vh;
  font-family: 'Raleway', sans-serif;
  cursor: pointer;
  color: white;
  text-decoration: none;
  -webkit-transition: color .5s;
  -o-transition: color .5s;
  transition: color .5s;
}

.divide {
  border-right: 1px solid Brown;
}

.About::first-letter {
  color: brown;
  text-shadow: 2px 2px black;
}

.Works::first-letter {
  color: brown;
  text-shadow: 2px 2px black;
}

.Projects::first-letter {
  color: brown;
  text-shadow: 2px 2px black;
}

.About:hover,
.Works:hover,
.Projects:hover {
  color: brown;
  text-shadow: 2px 2px black;
}

.links {
  position: absolute;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2vh;
  margin-top: 5vh;
}

.mail {
  text-decoration: none;
  color: white;
  -webkit-text-decoration: underline brown;
          text-decoration: underline brown;
}

.insta {
  display: block;
  width: 25px;
  color: brown;
}

footer a {
  margin-top: 20px;
}

.imdb {
  display: block; /*eliminate extra space at the bottom*/
  width: auto;
  height: 25px;
  color: brown;
}

@media screen and (min-width: 1921px) {
  .name {
    width: 45vw;
    }
}

@media screen and (max-width: 1024px) {

  .main {
    top: 10%;
  }

  .name {
    width: 100vw;
    min-width: 0;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .first {
    position: relative;
    left: 1%;
    font-size: 8vh;
    height: 8vh;
  }

  .second {
    position: relative;
    left: 1%;
    height: 14vh;
    font-size: 12vh;
    color: brown;
    text-shadow: 3px 2px black;
  }

  .bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 30vh;
  }

  .bar p:active {
    color: brown;
  }

  .links {
    margin-top: 50px;
    font-size: 3vh;
  }

}
