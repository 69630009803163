/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.cont {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(0, 0, 0, .7);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}

.trailer {
  width: 80vw;
  height: 80vh;
}

.closetrailer {
  position: absolute;
  right: 8%;
  top: 4%;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.closetrailer:before, .closetrailer:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 3px;
  background-color: brown;
}

.closetrailer:before {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.closetrailer:after {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media screen and (max-width: 1024px) {

  .trailer {
    width: 80vw;
    height: 70vh;
  }

}
