/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.workscont {
  position: absolute;
  padding: 100px;
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-around;
      -ms-flex-pack: space-around;
          justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-overflow-scrolling: touch;
}

.work {
  position: relative;
  width: 20vw;
  min-width: 384px;
  height: 56.76532769556025vh;
  min-height: 548.35px;
  max-height: 750px;
  display: flex;
  align-items: center;
  margin: 30px;
}

.over {
  position: absolute;
  width: 100%;
  height: auto;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .3s ease;
  cursor: default;
}

@media screen and (min-width: 1024px) { /*activate hover only on desktop*/

.work:hover .over {
  opacity: 1;
  transform: scale(1.2);
}
}

.title {
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: white;
  font-size: 2.5vh;
  font-family: Arial;
  text-align: center;
  background: rgba(0, 0, 0, .8);
}

.img {
  width: 100%;
  height: 100%;
  min-height: 100%;
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.info {
  text-align: justify;
  color: white;
  font-size: 2vh;
  font-family: Courier;
  border-radius: 10px;
  padding: 10px;
  background: rgba(0, 0, 0, .8);
}

.button {
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  color: white;
  font-size: 2.5vh;
  font-family: Arial;
  text-align: center;
  -webkit-text-decoration: underline brown;
          text-decoration: underline brown;
  cursor: pointer;
  background: rgba(0, 0, 0, .8);
}

.closeworks {
  position: absolute;
  right: 1%;
  top: 2%;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.closeworks:before, .closeworks:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 3px;
  background-color: brown;
}

.closeworks:before {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.closeworks:after {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media screen and (max-width: 1024px) {

  .workscont {
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .workcont {
    position: relative;
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
  }

  .work {
    width: 300px;
    min-width: 300px;
    height: 420px;
    min-height: 420px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .closeoverlay {
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .closeoverlay:before, .closeoverlay:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 3px;
    background-color: brown;
  }

  .closeoverlay:before {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
  }

  .closeoverlay:after {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

  .title {
    font-size: 4vh;
  }

  .info {
    font-size: 20px;
  }

  .button {
    font-size: 4vh;
  }

  .closeworks {
    right: 8%;
    top: 5%;
  }

}
